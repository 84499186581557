import React from 'react';
import ReactDOM from 'react-dom';
import "antd/dist/antd.css";
import './index.css';
import App from './App';
import './Webpages/survey.css';
import {BrowserRouter} from 'react-router-dom'
// import  './Components/Surveystyle.css';

ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  ,
  document.getElementById('root')
);


