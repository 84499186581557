import Surveyq1page from './Webpages/Surveyquestion1';
import {Routes,Route} from 'react-router-dom';
import { Page1 } from './Webpages/FirstPage';
import './Webpages/survey.css';
import Surveyq3page from './Webpages/Surveyquestion3';
import Surveyq4page from './Webpages/Surveyquestion4';
import Surveyq5page from './Webpages/Surveyquestion5';
import Surveyanalysis from './Webpages/Postsurvey';
import Surveyq2page from './Webpages/Surveyquestion2';
import Surveyq6page from './Webpages/Surveyquestion6';
import Surveyq7page from './Webpages/Surveyquestion7';
import Userdetails from './Webpages/Userdetails';
function App() {
  return (
   <Routes>
     <Route path="/" element={<div>
        <h1>404 Error</h1>
        <h1>Page Not Found</h1>
    </div>}/>
     

   </Routes>
  );
}

export default App;
