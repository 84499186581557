
import 'antd/dist/antd.css';
import { React, useState, useEffect } from "react";
import { Steps } from "antd";
// import axios from 'axios';
// import configData from '../config.json';
// import ScaleLoader from "react-spinners/ScaleLoader";


const { Step } = Steps;
const surveyArray = [];


function Questionprops(props) {
    const q_val = props.question1;

    return (
        <> {
            props.errorState && props.answers == null ?
                <div className='bgError'>

                    <ul style={{ margin: "0px", paddingTop: "2%", paddingBottom: "1.8%" }}> {props.question} </ul>

                </div>
                :
                <div className={props.number % 2 === 0 ? 'bgcolor' : "bgcolor2"}>

                    <ul style={{ margin: "0px", paddingTop: "2%", paddingBottom: "1.8%" }}>{props.question} </ul>

                </div>
        }

        </>

    );
}
function Bullet(props) {
    return (
        <div style={{ height: "100%" }} >
            {props.selected &&
                <div style={{  width: "33px", height: "33px", border: "2px solid #1463cc", borderRadius: "50%", textAlign: "center", backgroundColor: "#0056c8", alignItems: "center", fontSize: "18px", fontWeight: "bold", color: "White" }}>
                    {props.number}
                </div>}
            {!props.selected &&
                <div style={{  width: "33px", height: "33px", border: "2px solid #1463cc", borderRadius: "50%", backgroundColor: "white",  fontSize: "15px", fontWeight: "bold", color: "#0057c8" }}>
                    <div >
                    {props.number}
                    </div>
                </div>}
        </div>
    )
}

function Questionforms(props) {
    const [current, setCurrent] = useState(-1);

    const surveyDetails = {
        "SectionId": "",
        "QuestionId": "",
        "ResponseOption": ""
    }

    surveyDetails.SectionId = props.questionObject.SectionId;
    surveyDetails.QuestionId = props.questionObject.QuestionId;
    surveyDetails.ResponseOption = current + 1;
    surveyArray.push(surveyDetails);

    props.setQuestionObject(surveyArray);
    function onChange(current, key) {
        setCurrent(current);

        let tempArray = [...props.answers];
        tempArray[props.questionNumber - 1] = current;
        props.setAnswers(tempArray);
    }

    return (
        <div style={{ height: "100%" }}>

            {
                props.errorState && props.answers[props.index] == null ?
                    <div style={{ display: "flex", alignItems: "center", paddingLeft: "3.7%", paddingRight: "4%" }} 
                    className='bgError'>
                        <Steps current={current} onChange={(e) => onChange(e)} >
                            <Step key={0} icon={<Bullet number={1} selected={current === 0 ? true : false} /> } />
                            <Step key={1} icon={<><Bullet number={2} selected={current === 1 ? true : false} /></>} />
                            <Step key={2} icon={<><Bullet number={3} selected={current === 2 ? true : false} /></>} />
                            <Step key={3} icon={<><Bullet number={4} selected={current === 3 ? true : false} /></>} />
                            <Step key={4} icon={<><Bullet number={5} selected={current === 4 ? true : false} /></>} />
                            <Step key={5} icon={<><Bullet number={6} selected={current === 5 ? true : false} /></>} />
                            <Step key={6} icon={<><Bullet number={7} selected={current === 6 ? true : false} /></>} />
                            <Step key={7} icon={<><Bullet number={"?"} selected={current === 7 ? true : false} /></>} />
                        </Steps>
                    </div>

                    :

                    <div style={{ display: "flex", alignItems: "center", paddingLeft: "3.7%", paddingRight: "4%" }} 
                    className={props.number % 2 === 0 ? 'bgcolor' : "bgcolor2"} >
                        <Steps  current={current} onChange={(e) => onChange(e)} >
                            <Step key={0} icon={<><Bullet number={1} selected={current === 0 ? true : false} /> </>} />
                            <Step key={1} icon={<><Bullet number={2} selected={current === 1 ? true : false} /></>} />
                            <Step key={2} icon={<><Bullet number={3} selected={current === 2 ? true : false} /></>} />
                            <Step key={3} icon={<><Bullet number={4} selected={current === 3 ? true : false} /></>} />
                            <Step key={4} icon={<><Bullet number={5} selected={current === 4 ? true : false} /></>} />
                            <Step key={5} icon={<><Bullet number={6} selected={current === 5 ? true : false} /></>} />
                            <Step key={6} icon={<><Bullet number={7} selected={current === 6 ? true : false} /></>} />
                            <Step key={7} icon={<><Bullet number={"?"} selected={current === 7 ? true : false} /></>} />
                        </Steps>
                    </div>
            }
        </div>

    );
}

export default function Demo(props) {
    const button_statements=props.button_statements
    console.log(button_statements)
    // const [details, setDetails] = useState(null);
    // const [isLoading, setIsLoading] = useState(true);
    // const url = configData.API_URL;

    // useEffect(() => {
    //     getResponseOptions();
    // }, []);


    // /**
    //      * method to get details from database
    //      */
    // const getResponseOptions = async () => {
    //     await axios.get(`${url}/GetResponseOptions`, { headers: { 'x-api-key': configData.API_KEY } })
    //         .then((response) => {
    //             const formDetails = response.data;
    //             setDetails(formDetails);
                
    //         })

    //         .catch(error => console.error(`Error :${error}`))
    //         // .then((response) => 
    //         // setIsLoading(false) ) ;
            
           
    // };

    // const button_statements = details && details.Response.Options.map(item => item.ResponseOption);
    // let len_btn_statements = button_statements && button_statements.length;
    // console.log(len_btn_statements);

    return (
        <div style={{ backgroundColor: "white", marginLeft: "2%", marginRight: "2%", marginTop: "1%", position: "relative" }}>
            
            <div
                style={{ height: "99%", position: "absolute", width: "1px", right: "10%", top: "2%", backgroundColor: "grey" }}
            >
            </div>

            <div style={{ backgroundColor: "white", marginLeft: "2%", marginRight: "2%", marginTop: "1%" }}>
                <div className="grid-container-body">

                    <div style={{ paddingTop: "6%" }}><b >Please indicate how much you agree with these statements:</b></div>
                    <div className="header">
                        {

                            button_statements && button_statements.map((button_statement, idx) =>
                            (idx % 2 && idx !== 7 ? //added check to even index and added exception to skip the 7th index
                                <div style={{ paddingTop: "1.8rem" }}>
                                    <b>{button_statement}</b>
                                </div>
                                :
                                <div>
                                    <b>{button_statement}</b>
                                </div>
                            ))
                        }
                    </div>
                </div>
                {
                    props.questionObject.map((object, index) => {
                        return (
                            <div className="grid-container-body">
                                <div >
                                    <Questionprops question={object.Question} answers={props.answers[index]}
                                        number={index + 2} errorState={props.errorState} sectionId={object.SectionId}
                                        questionId={object.QuestionId} />
                                </div>
                                <div >
                                    <Questionforms index={index} answers={props.answers} setAnswers={props.setAnswers}
                                        setQuestionObject={props.setQuestionObject}
                                        number={index + 2} questionNumber={index + 1} errorState={props.errorState}
                                        questionObject={object} />
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
 
    )
}