//import { Link } from "react-router-dom";
import { Checkbox, Select } from 'antd';
import { Form, Input } from 'antd';
// import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import configData from '../config.json'
import { RuleObject } from "rc-field-form/lib/interface";

const { Option } = Select;

export default function Userdetails(route) {

    const Errmessage = (message) => {
        console.log("message", message)
    }
    const location = useLocation();
    // const url = configData.API_URL;
    const url = process.env.REACT_APP_API_URL;

    const [details, setDetails] = useState(null);
    const [isCommunicationEnabled, setCommunicationFlag] = useState(false);

    useEffect(() => {
        getFormDetails();
    }, []);

    const Request = {
        "Request": {
            "UserDetails": {
                "Name": "",
                "WorkEmail": "",
                "JobTitle": "",
                "CompanySize": "",
                "PrimaryIndustry": "",
                "Country": "",
                "IsCommunicationEnabled": true
            },
            "SurveyDetails": [
                {
                    "SectionId": 0,
                    "QuestionId": 0,
                    "ResponseOption": 0
                }
            ]
        }
    }

    /**
     * method to get details from database
     * this contains list of Company Size, Primary Industry, Country and Job Title.
     */
    const getFormDetails = async () => {
        await axios.get(`${url}/GetFormDetails`, { headers: { 'x-api-key': process.env.REACT_APP_API_KEY } })
            .then((response) => {
                const formDetails = response.data;
                setDetails(formDetails);
            }).catch(error => console.error(`Error :${error}`));

    };



    const handleChange = (e) => {
        let isChecked = e.target.checked;
        setCommunicationFlag(isChecked);
    }

    const layout = {

        labelCol: {
            span: 5,
            offset: 9,

        },

        wrapperCol: {
            span: 7,
            offset: 9
        },

    };

    const [errorMessage, setErrorMessage] = useState("");
    const handleClick = () => {
        setErrorMessage(
            <div style={{ color: "red", marginLeft: "-13%" }}>"Please fill all the details"
            </div>
        )
        // <div>
        //     please fill all the details
        // </div>
    }
    let navigate = useNavigate();
    const onFinish = (values) => {
        console.log("Values ", values);
        Request.Request.UserDetails.CompanySize = values.user["Company Size"];
        Request.Request.UserDetails.Country = values.user["Country"];
        Request.Request.UserDetails.JobTitle = values.user["Job title"];
        Request.Request.UserDetails.WorkEmail = values.user["Work Email"];
        Request.Request.UserDetails.PrimaryIndustry = values.user["primaryindustry"];
        Request.Request.UserDetails.Name = values.user["Full Name"];
        Request.Request.UserDetails.IsCommunicationEnabled = isCommunicationEnabled;
        Request.Request.SurveyDetails = location.state;

        navigate('/postsurvey', { state: Request });


    };

    const validation = (rule, value, callback) => {
        if (isCommunicationEnabled) {
            return callback()
        }
        return callback("")
    };

    return (
        <div style={{ backgroundColor: "#E5E5E5", height: "55rem" }} >
            <div className='bgheaderimg'>
                <div className='imgtext'>ZS Data Strategy Diagnostic</div>
            </div>
            <div style={{ marginLeft: "2%", marginRight: "2%" }}>
                <div className='section'><b>Post Survey</b>
                    {/* <div class="grid-container">
                        <div style={{ backgroundColor: "#FFB234" }}></div>
                        <div style={{ backgroundColor: "#FFB234" }}></div>
                        <div style={{ backgroundColor: "#FFB234" }}></div>
                        <div style={{ backgroundColor: "#FFB234" }}></div>
                        <div style={{ backgroundColor: "#FFB234" }}></div>
                        <div style={{ backgroundColor: "#FFB234" }}></div>
                        <div style={{ border: "2px solid #FFB234" }}></div>
                    </div> */}
                </div>
            </div>
            <div className='compltmsg' style={{ backgroundColor: "white", marginLeft: "2%", marginRight: "2%", marginTop: "1%" }}>
                <div style={{ paddingLeft: "3%", paddingTop: "2%" }}>Great job! You’ve completed the survey. </div>
                <div style={{ paddingLeft: "3%" }}>Now please tell us a little about yourself and your organization, and we’ll show your results.</div>
            </div>
            <div style={{ backgroundColor: "white", marginLeft: "2%", marginRight: "2%", paddingTop: "2%" }}>
                <Form  {...layout} layout="vertical" name="nest-messages" onFinish={onFinish}
                    Errmessage={Errmessage} autoComplete="off"

                >
                    <Form.Item style={{ marginBottom: 0 }}
                        name={['user', 'Full Name']}
                        label="Full Name"
                        rules={[
                            {
                                message: "",
                                required: true,

                            },
                        ]}
                    >
                        <Input
                            pattern="[a-zA-Z'-'\s]*"
                            placeholder="John Doe"
                            title='(Only alphabets are allowed)'
                            style={{ marginBottom: 2 }} />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}
                        name={['user', 'Work Email']}
                        label="Work Email"
                        rules={[
                            {
                                message: '',
                                required: true,

                            },
                        ]}
                    >
                        <Input type="email" placeholder="work@email.com" style={{ marginBottom: 5 }} />
                        {/* https://www.kindacode.com/article/live-email-validation-in-react-with-regex/ */}
                        {/* <div className={`message ${isValid ? 'success' : 'error'}`}>
                    {message}
                    </div> */}

                    </Form.Item>

                    <Form.Item style={{ marginBottom: 0 }}
                        name={['user', 'Job title']}
                        label="Job Title"
                        rules={[
                            {
                                message: '',
                                required: true,

                            },
                        ]}
                    >
                        {/* <Input placeholder="Software Engineer"/> */}
                        <Select placeholder="Select" style={{ marginBottom: 5 }}>
                            {details &&
                                details.Response.LevelOrganization.map(function (title) {
                                    return (<Option value={title} key={title}>{title}</Option>)
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}
                        name={['user', 'Company Size']}
                        label="Company Size"
                        rules={[
                            {
                                message: '',
                                required: true,

                            },
                        ]}
                    >
                        {/* <Input placeholder="please select"/> */}
                        <Select placeholder="Select" style={{ marginBottom: 5 }}>
                            {
                                details &&
                                details.Response.CompanySize.map(function (size) {
                                    return (<Option value={size} key={size}>{size}</Option>)
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}
                        name={['user', 'Country']}
                        label="Country "
                        rules={[
                            {
                                message: '',
                                required: true,

                            },
                        ]}
                    >
                        {/* <Input placeholder="please select"/> */}
                        <Select placeholder="Select" style={{ marginBottom: 5 }}>
                            {
                                details &&
                                details.Response.Country.map(function (country) {
                                    return (<Option value={country} key={country}>{country}</Option>)
                                })
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item style={{ marginBottom: 0 }}
                        name={['user', 'primaryindustry']}
                        label="Primary Industry"
                        rules={[
                            {
                                message: '',
                                required: true,

                            },
                        ]}
                    >
                        {/* <Input placeholder="please select"/> */}
                        <Select placeholder="Select" style={{ marginBottom: 5 }}>
                            {
                                details &&
                                details.Response.PrimaryIndustry.map(function (industry) {
                                    return (<Option value={industry} key={industry}>{industry}</Option>)
                                })
                            }

                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={['user', 'Question']}
                        label=" "
                        rules={[{ validator: validation }]}>
                        <div>
                            <p>  <span style={{
                            display: 'inline-block',
                            marginRight: '4px',
                            color: '#ff4d4f',
                            fontSize: '14px',
                            fontFamily: 'SimSun, sans-serif',
                            lineHeight: 1,
                            content: '*'
                        }}>*</span><i>By participating in this survey, you agree that we may process your
                                information for the purpose of analyzing survey responses,
                                reporting on aggregate or anonymized features,
                                contacting you with related questions, and otherwise use and transfer
                                information according to our <a href="https://www.zs.com/privacy-policy"
                                    target="_blank" rel="noopener noreferrer"><u>privacy policy</u></a></i>.</p>

                            <Checkbox checked={isCommunicationEnabled} id="checkbox" onChange={e => handleChange(e)}>
                            </Checkbox> Yes, I agree.
                        </div>

                    </Form.Item>
                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 12 }} >
                        {errorMessage && <div className='error'>
                            {errorMessage}
                        </div>}
                        {/* <Button type="primary" htmlType="submit" style={{backgroundColor:"#0054C9"}} onClick={handleClick}>
                    View results
                    </Button> */}
                        <div>
                            <button className='rsltbtn' onClick={() => { handleClick() }}>
                                View results
                            </button>

                            {/* <button className='rsltbtn' onClick={handleClick}>
                            View results
                            </button> */}
                        </div>
                    </Form.Item>
                </Form>
            </div>



        </div>
    )

}
